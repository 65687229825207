<template>
  <PageWrapper>
    <div class="w-full flex flex-col">
      <Header v-slot="{ _headerClass, _subheaderClass }">
        <h1 :class="_headerClass">Household Interview</h1>
        <p :class="_subheaderClass">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi
          fugit enim facere aliquid, vitae sapiente distinctio voluptatem ut.
          Quae, consequuntur!
        </p>
      </Header>
      <div class="w-full grid grid-cols-1" v-if="primaryResidence && questions">
        <Question
          v-for="question in questions"
          @answerQuestion="answerQuestion"
          :questionGuid="question.questionGuid"
          :key="question.questionId"
          :questionText="question.questionText"
          :availableAnswers="question.availableAnswers"
          :controlType="question.controlType"
          :answer="question.answer"
          :questionScope="question.questionScope"
          :level="question.level"
          :transition="transition"
        />
      </div>
      <div class="w-full flex justify-end items-center mt-4">
        <router-link
          :to="{ name: 'household' }"
          class="btn-secondary py-2 w-24 rounded text-sm mx-2"
        >
          Back</router-link
        ><router-link
          :to="{ name: 'resident-interview', params: { id: 1 } }"
          class="btn-primary text-sm py-2 w-24 rounded"
          >Continue</router-link
        >
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import { computed, ref } from "vue";
import {
  getQuestionsRecursively,
  findQuestionRecursively,
} from "../../utils/functions";
import useBenefitRecord from "../../composables/useBenefitRecord";
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
import Question from "../../components/home/ui/Question.vue";
import Header from "../../components/home/ui/Header.vue";
export default {
  components: {
    PageWrapper,
    Question,
    Header,
  },
  setup() {
    const { primaryResidence, determineEligibility } = useBenefitRecord();
    const questions = computed(() =>
      getQuestionsRecursively(0, primaryResidence.value.questionsAnswers)
    );
    const transition = ref(false);
    const answerQuestion = async (questionGuid, answer) => {
      transition.value = true;
      const question = findQuestionRecursively(questionGuid, questions.value);
      if (question) {
        question.answer = answer;
        await determineEligibility();
      }
    };

    return {
      primaryResidence,
      questions,
      answerQuestion,
      transition,
    };
  },
};
</script>
